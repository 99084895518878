import Topbar from "./components/topbar/Topbar";
import Dashboard from "./pages/dashboard/Dashboard";
import "./app.css"

function App() {
  return (
    <div className="app">
      <Topbar/>
      <div className="container">
        <Dashboard />
      </div>
    </div>
  );
}

export default App;
