import "./chatbot1.css"

export default function Chatbot1() {
    return (
        <div className="tout">
        <div className="first-big-row">
        <div className="Chatbot">

            <div className="premier">
                <div className="affich">
                    <div className="Title">Chatbot v2.6</div>
                    <div className="Tests">
                        17 tests
                    </div>
                </div>
                <div className="test">
                    <div className="latestrun">LATEST TEST RUN 4 HOURS AGO</div>
                    <div className="pourcentages">
                        <div className="correctness">Correctness %      
                        <svg viewBox="0 0 36 36" class="circular-chart">
                        <path class="circle"
                            stroke-dasharray="79, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        
                        </svg><div className="commentun">79 %</div></div>
                        <div className="confidence">Confidence %
                        <svg viewBox="0 0 36 36" class="circular-chart-deux">
                        <path class="circle-deux"
                            stroke-dasharray="91, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        
                        </svg><div className="commentdeux">91 %</div>
                        </div>
                        <div className="clarity">Clarity %
                        <svg viewBox="0 0 36 36" class="circular-chart-trois">
                        <path class="circle-trois"
                            stroke-dasharray="96, 100"
                            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        />
                        
                        </svg><div className="comment-trois">96 %</div>
                        </div>
                    </div>
                </div>
            </div>

            
            
        </div>
        <div className="second-box">
            <div className="first-row-subject">
                <div className="titre-subject">Subject matter test</div>
                <div className="Tests-matter">
                        9 tests
                </div>
            </div>
            <div className="second-row-latest-test">
                <div className="text-second-row">LATEST TEST RUN - 6 HOURS AGO</div>
            </div>
            <div className="third-row-percentages">

                <div className="third-row-Correctness">
                    <div className="text-correctness">Correctness %</div>
                    <div className="percentage-value-correctness">61</div>
                    <hr className="correctness-line"></hr>
                </div>
                <div className="third-row-Confidence">
                    <div className="text-confidence">Confidence %</div>
                    <div className="percentage-value-confidence">62</div>
                    <hr className="confidence-line"></hr>
                </div>
                <div className="third-row-Clarity">
                    <div className="text-clarity">Clarity %</div>
                    <div className="percentage-value-clarity">82</div>
                    <hr className="clarity-line"></hr>
                </div>
            </div></div>
        </div>
        <div className="second-big-row">
            <div className="first-box-second-row">
            <div className="first-row-subject">
                <div className="titre-subject">January updates v0.3 </div>
                <div className="Tests-matter">
                        16 tests
                </div>
            </div>
            <div className="second-row-latest-test">
                <div className="text-second-row">LATEST TEST RUN - 6 HOURS AGO</div>
            </div>
            <div className="third-row-percentages">

                <div className="third-row-Correctness">
                    <div className="text-correctness">Correctness %</div>
                    <div className="percentage-value-correctness">91</div>
                    <hr className="correctness-line-second"></hr>
                </div>
                <div className="third-row-Confidence">
                    <div className="text-confidence">Confidence %</div>
                    <div className="percentage-value-confidence">96</div>
                    <hr className="confidence-line-second"></hr>
                </div>
                <div className="third-row-Clarity">
                    <div className="text-clarity">Clarity %</div>
                    <div className="percentage-value-clarity">99</div>
                    <hr className="clarity-line-second"></hr>
                </div>
            </div>
            </div>
            <div className="second-box-second-row">
            <div className="first-row-subject">
                <div className="titre-subject">Regression test </div>
                <div className="Tests-matter">
                        2 tests
                </div>
            </div>
            <div className="second-row-latest-test">
                <div className="text-second-row">LATEST TEST RUN - 6 HOURS AGO</div>
            </div>
            <div className="third-row-percentages">

                <div className="third-row-Correctness">
                    <div className="text-correctness">Correctness %</div>
                    <div className="percentage-value-correctness">35</div>
                    <hr className="correctness-line-third"></hr>
                </div>
                <div className="third-row-Confidence">
                    <div className="text-confidence">Confidence %</div>
                    <div className="percentage-value-confidence">32</div>
                    <hr className="confidence-line-third"></hr>
                </div>
                <div className="third-row-Clarity">
                    <div className="text-clarity">Clarity %</div>
                    <div className="percentage-value-clarity">40</div>
                    <hr className="clarity-line-third"></hr>
                </div>
            </div>
            



        </div>
        <div className="third-box-second-row">
            <div className="first-row-subject">
                <div className="titre-subject">Chatbot v2.5</div>
                <div className="Tests-matter">
                        1 test
                </div>
            </div>
            <div className="second-row-latest-test">
                <div className="text-second-row">LATEST TEST RUN - 7 HOURS AGO</div>
            </div>
            <div className="third-row-percentages">

                <div className="third-row-Correctness">
                    <div className="text-correctness">Correctness %</div>
                    <div className="percentage-value-correctness">60</div>
                    <hr className="correctness-line-fourth"></hr>
                </div>
                <div className="third-row-Confidence">
                    <div className="text-confidence">Confidence %</div>
                    <div className="percentage-value-confidence">82</div>
                    <hr className="confidence-line-fourth"></hr>
                </div>
                <div className="third-row-Clarity">
                    <div className="text-clarity">Clarity %</div>
                    <div className="percentage-value-clarity">62</div>
                    <hr className="clarity-line-fourth"></hr>
                </div>
            </div>
            



        </div>
        
        </div>
        <div className="third-big-row">
            <div className="box-third-row">
                
                    <div className="afichage">
                     <div className="Title-1">December updates v0.8</div>
                        <div className="Tests-1">
                            17 tests
                    </div>
                
                    </div>
            </div>
            <div className="box-third-row">
                
                    <div className="afichage">
                     <div className="Title-1">Experimental test data</div>
                        <div className="Tests-1">
                            2 tests
                    </div>
                
                    </div>
            </div>
            <div className="box-third-row">
                
                    <div className="afichage">
                     <div className="Title-1">December updates v0.8</div>
                        <div className="Tests-1">
                            17 tests
                    </div>
                
                    </div>
            </div>


        </div>
        </div>
        

    )
}
