import Chatbot1 from "../../components/Chatbot1/chatbot1"

import "./dashboard.css"

export default function Dashboard() {
    return (
        <div className="dashboard">
            <Chatbot1 />
        </div>
    )
}
